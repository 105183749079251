import { useState, useEffect } from 'react';
import { LdsModal, LdsTextField, LdsButton, LdsValidationError, LdsToast, useToastContext, LdsSelect } from '@elilillyco/ux-lds-react';
import { useDispatch, useSelector } from 'react-redux';
import { addCategory } from '../../../store/Components/TACCT/BL/taactaddcategory';
import "../../../assets/components/TACCT/BL/addCat.scss";
import CreatableSelect from "react-select/creatable";

const AddNewObjModal = (props) => {
    const { modalOpen, setIsAddNewObjModalOpen } = props;
    const { addToast } = useToastContext();
    const [caterrors, setCatError] = useState(null);
    const [catdeferrors, setCatDefError] = useState(null);
    const role2 = useSelector(({ user }) => user.user.role_ids);
    
    const dispatch = useDispatch();

    const toastConfig = (errTxtMsg, msgType) => {
        return {
            toastMessage: errTxtMsg,
            actionText: "",
            actionCallback: () => {},
            variant: msgType,
            position: "top",
            align: "center",
            dismissible: true,
            light: false,
            timeout: 5000,
            inline: false,
            autoDismiss: true,
        };
    };

    const getUserID = () => {
        let currentRole = sessionStorage.getItem('role');
        let currentUserData = role2.filter((roleData) => Object.keys(roleData)[0] === currentRole);
        let userRole = currentUserData[0][currentRole];
        return userRole;
    };

    const makesubmitapicall = async (val1, val2) => {
        let tacctListID = getTacctListID(selectedTacctOption);
        let srcSysID = selectSourceType === 'APIMS' ? 2 : 1;
        let resultdata = await dispatch(addCategory({
            "object_data": {
                "tacct_list_id": tacctListID,
                "obj_name": val1,
                "src_sys_id": srcSysID,
                "obj_detail": val2,
                "obj_status": "ACTIVE",
                "obj_type": selectedObjTypeOption,
                "obj_start_dt": "2025-01-01",
                "obj_end_dt": "9999-12-31"
            }
        }));
        if (resultdata.payload.statusCode === 200 && resultdata.payload.statusMessage === 'Duplicate entry found for object') {
            addToast(toastConfig("Dupliacte Entry.", "error"));
            return false;
        } else {
            addToast(toastConfig("Your changes have been saved successfully.", "success"));
            setIsAddNewObjModalOpen(false);
            resetForm();
        }
    };

    const HandleAddNewCatFormSubmit = (e) => {
        e.preventDefault();
        const form = e.target;
        const formData = new FormData(form);
        let cat = formData.get('object').trim();
        let catopdef = formData.get('objectdef').trim();
        let formerror = false;

        if (cat === '' || catopdef === '') {
            addToast(toastConfig("Please add both the fields!", "error"));
            formerror = true;
            return;
        }

        if (cat.match(/[^a-zA-Z0-9\s_-]/) || catopdef.match(/[^a-zA-Z0-9\s_-]/)) {
            addToast(toastConfig("Special characters are not allowed.", "error"));
            formerror = true;
            return;
        }

        if (!formerror) {
            makesubmitapicall(cat, catopdef);
        }
    };

    const checkRules = (e) => {
        e.target.value = e.target.value.toUpperCase();
        const targetID = e.target.id;
        if (!/^[a-zA-Z0-9\s_-]+$/g.test(e.target.value)) {
            (targetID === 'object') ? setCatError("Invalid Format") : setCatDefError("Invalid Format");
        } else {
            (targetID === 'object') ? setCatError(null) : setCatDefError(null);
        }
    };

    const getTacctListID = (tacctList) => {
        switch (tacctList) {
            case 'TACCT_Ref':
                return 1;
            case 'Blue_List_Content_Category_Subcategory':
                return 3;
            case 'VeevaVault_Doc_Types':
                return 7;
            case 'Medical_Abbrev_Format':
                return 5;
            case 'Medical_Abbrev_Type':
                return 4;
            default:
                return null;
        }
    };

    const [selectSourceType, setSelectSourceType] = useState(null);
    const [tacctListOptions, setTacctListOptions] = useState([null]);
    const [ojectTypeOptions, setObjectTypeOptions] = useState([null]);
    const [selectedTacctOption, setSelectedTacctOption] = useState(null);
    const [selectedObjTypeOption, setSelectedObjTypeOption] = useState(null);
    const [object, setObject] = useState('');
    const [objectdef, setObjectDef] = useState('');
    

    const ST = ['APIMS', 'TACCT'];
    const Apims = ['TACCT_Ref'];
    const Tacct = ['Blue_List_Content_Category_Subcategory', 'VeevaVault_Doc_Types', 'Medical_Abbrev_Format', 'Medical_Abbrev_Type'];
    const tacct_ref = ['BRND', 'BSUT', 'CCML', 'DSST', 'IND', 'IND_GRP', 'MDPR', 'NPRD', 'THPC'];
    const bluelist = ['BLCAT', 'BLSUB'];
    const veevavault = ['VVCLA', 'VVSUB', 'VVTYP'];
    const medicalformat = ['ASSOC', 'BIOMT', 'DIAG', 'DSST2', 'MDCTN', 'NA', 'TBD', 'TEST', 'TPHC2'];
    const mediacltype = ['MABTY'];

    const handleSourceTypeChange = (e) => {
        const selectedValue = e?.value;
        setSelectSourceType(selectedValue);
        setObjectTypeOptions([]);
        if (selectedValue === 'APIMS') {
            const apimsOptions = Apims.map((item) => ({ label: item, value: item }));
            setTacctListOptions([ ...apimsOptions]);
        } else if (selectedValue === 'TACCT') {
            const tacctOptions = Tacct.map((item) => ({ label: item, value: item }));
            setTacctListOptions([ ...tacctOptions]);
        } else {
            setTacctListOptions([]);
        }
    };

    useEffect(() => {
        if (selectSourceType === 'APIMS' && selectedTacctOption === 'TACCT_Ref') {
            const selectedObjTypeOption = tacct_ref.map((item) => ({ label: item, value: item }));
            setObjectTypeOptions([ ...selectedObjTypeOption]);
        } else if (selectSourceType === 'TACCT' && selectedTacctOption === 'Blue_List_Content_Category_Subcategory') {
            const selectedObjTypeOption = bluelist.map((item) => ({ label: item, value: item }));
            setObjectTypeOptions([ ...selectedObjTypeOption]);
        } else if (selectSourceType === 'TACCT' && selectedTacctOption === 'VeevaVault_Doc_Types') {
            const selectedObjTypeOption = veevavault.map((item) => ({ label: item, value: item }));
            setObjectTypeOptions([ ...selectedObjTypeOption]);
        } else if (selectSourceType === 'TACCT' && selectedTacctOption === 'Medical_Abbrev') {
            const selectedObjTypeOption = medicalformat.map((item) => ({ label: item, value: item }));
            setObjectTypeOptions([ ...selectedObjTypeOption]);
        } else if (selectSourceType === 'TACCT' && selectedTacctOption === 'Medical_Abbrev_Type') {
            const selectedObjTypeOption = mediacltype.map((item) => ({ label: item, value: item }));
            setObjectTypeOptions([ ...selectedObjTypeOption]);
        } 
        else {
            setObjectTypeOptions([]);   
        }
    }, [selectSourceType, selectedTacctOption]);

    const resetForm = () => {
        setSelectSourceType();
        setTacctListOptions([]);
        setObjectTypeOptions([]);
        setSelectedTacctOption();
        setSelectedObjTypeOption();
        setObject('');
        setObjectDef('');
        
    };

    return (
        <div className="add-cat-modal">
            <LdsModal
                modalId="AddNewObjModal"
                open={modalOpen}
                setModalOpen={setIsAddNewObjModalOpen}
                persistent
            >
                <div className="modal-header">
                    <span className="close-btn" onClick={() => {  resetForm(); setIsAddNewObjModalOpen(false); }}>&#x2715;</span> 
                </div>
                <h2 className="modal-title">Add Object</h2>
                <form onSubmit={HandleAddNewCatFormSubmit}>
                    <div className='d-flex'>
                        <div className="col-6 lds-form-field row-field">
                            <div className='d-flex '>
                                <label>Source Type</label>
                                &nbsp;&nbsp;
                                <div className='new_select'>
                                    <LdsSelect
                                        id="sourceType"
                                        name="sourceType"
                                        value={selectSourceType}
                                        options={[{ value: "", label: "Select Source Type" }, ...(ST || []).map((item) => ({ label: item, value: item }))]}
                                        onChange={handleSourceTypeChange}
                                    />
                                </div>
                            </div>&nbsp;&nbsp;  
                            {console.log("selectedTacctOption", selectedTacctOption)}
                            <div className='d-flex '>
                                <label>TACCT List</label>
                                &nbsp;&nbsp;&nbsp;&nbsp;
                                <div className='new_select'>
                                    <CreatableSelect
                                        id="tacctlist"
                                        name="tacctlist"
                                        value={selectedTacctOption?{ value: selectedTacctOption, label: selectedTacctOption } : ""}
                                        options={tacctListOptions ||[]}
                                        onChange={(e) => setSelectedTacctOption(e.value)}
                                        placeholder="Select TACCT List"
                                    />
                                </div>
                            </div>&nbsp;&nbsp;
                            <div className=' d-flex '>
                                <label>Object Type</label>
                                &nbsp;&nbsp;&nbsp;
                                <div className='new_select'>
                                    <CreatableSelect
                                        id="objecttype"
                                        name="objecttype"
                                        value={selectedObjTypeOption?{ value: selectedObjTypeOption, label: selectedObjTypeOption } : ""}
                                        options={ojectTypeOptions || []}
                                        onChange={(e) => setSelectedObjTypeOption(e.value)}
                                        placeholder="Select Object Type"
                                    />
                                </div>  
                            </div>
                        </div>
                        
                        <div className="col-3 lds-form-field row-field">
                            <label>Object <span className="asterik-required">*</span></label>
                            <div>
                                <LdsTextField
                                    required
                                    id='object'
                                    name='object'
                                    value={object}
                                    // value={object}
                                    error={caterrors && true}
                                    onChange={(e) =>{setObject(e.target.value); checkRules(e)}}
                                    spellCheck="false" autoComplete="off" aria-autocomplete="none"
                                />
                                {caterrors && <LdsValidationError id="errCategory">{caterrors}</LdsValidationError>}
                            </div>
                            <label>Object Details <span className="asterik-required">*</span></label>
                            <div>
                                <LdsTextField
                                    required
                                    id='objectdef'
                                    name='objectdef'
                                    // value={objectDef}
                                    value={objectdef} 
                                    onChange={(e) =>{setObjectDef(e.target.value); checkRules(e)}}
                                    spellCheck="false" autoComplete="off" aria-autocomplete="none"
                                    error={catdeferrors && true}
                                />
                                {catdeferrors && <LdsValidationError id="errCategoryOpDef">{catdeferrors}</LdsValidationError>}
                            </div>
                        </div> 
                    </div>
                    <div className="lds-form-field row-field" align="center">
                    <div>
                        <LdsButton className="cancelBtn" onClick={() => { setIsAddNewObjModalOpen(false); resetForm(); }}>Cancel</LdsButton>
                        <LdsButton type="submit">Add</LdsButton>
                    </div>
                </div>
                </form>
                
            </LdsModal>
            <LdsToast />
        </div>
    );
};

export default AddNewObjModal;
