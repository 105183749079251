import React, { useEffect } from 'react'
import { LdsTile, LdsTable, LdsLoadingSpinner, LdsIcon, LdsButton, LdsPagination, LdsTextField, LdsTooltip, LdsModal, useLdsModal, LdsCheckbox, LdsRadio, LdsRadioGroup, LdsOptionSelectorItem, LdsOptionSelector, LdsSelect, useToastContext } from '@elilillyco/ux-lds-react';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import HeaderNav from '../../HeaderNav';
import "../../../assets/components/TACCT/BL/blueList.scss"
import { fetchTacctData, getEntriesToExport } from "../../../store/Components/TACCT/BL/tacctmetadata";
import { Link } from "react-router-dom";
import ExportDataCSV from '../../../data/utils/ExportDataCSV';
import AddNewObjModal from './AddNewObjModal';
//import AddNewCatModal from "./AddNewCatModal";
import HistoryModal from '../../shared/HistoryModal';
import { useNavigate } from "react-router-dom";
import pako from "pako";
import { blArchiveValues } from '../../../api/actions/TxmyMgmt/tacctBlOverview/blArchive';
import { blArchive } from '../../../store/Components/TACCT/BL/tacctArchive';
import Chatbot from '../../../pages/Chatbot';
import infoToastConfig from '../../../data/utils/infoToastConfig';

export default function BlueList() {

  const navigate = useNavigate();
  const { addToast } = useToastContext();

  const { isModalOpen: isAddNewObjModalOpen, setIsModalOpen: setIsAddNewObjModalOpen } = useLdsModal();
  const [tacticId, setTacticId] = useState(null);
  const { isModalOpen: isAuditModalOpen, setIsModalOpen: setAuditIsModalOpen } = useLdsModal();
  const [tableData, setTableData] = useState([]);
  const [noDataMessage, setNoDataMessage] = useState(''); // State for the no data message
  const [currentPage, setCurrentPage] = useState(1);

  const filteredData = tableData?.map(item => {
    const { tacct_list_value, rcd_count, ...rest } = item; // Remove tacct_list_value & rcd_count
    return rest;
  });
  const columns = Object.keys(filteredData[0] || {})
  // const archiveIt = () => {
  //   dispatch(blArchive(Retpayload))
  // }
  const options = [
    { value: 'Medical_Abbrev_Type', label: 'Medical Abbrev Type' },
    { value: 'Tonality', label: 'Tonality' },
    { value: 'Medical_Abbrev_Format', label: 'Medical Abbrev Format' },
    { value: 'TACCT_Ref', label: 'TACCT Ref' },
    { value: 'Blue_List_Content_Category_Subcategory', label: 'Blue List CCS' },
    { value: 'Grammatical_Form', label: 'Grammatical Form' },
    { value: 'VeevaVault_Doc_Types', label: 'VeevaVault Doc Types' },
  ];

  const [selectedValue, setSelectedValue] = useState(options[0].value);
  const [laoder, setLoader] = useState(false);
  const handleChange = (event) => {
    const value = event?.value;
    setSelectedValue(value);
  };

  function RfaButton() {
    window.location.href = '/tacct/request-approval';
  }

  const dispatch = useDispatch();
  useEffect(() => {
    setLoader(true);
    dispatch(fetchTacctData({
      tacctListVal: selectedValue,
      pageNumber: currentPage,
      pageSize: 10,

    })).then((data) => {
      setTableData(data?.payload?.data);
      if (data?.payload?.statusCode === 200) {
        setTableData(data.payload.data); // Populate table with data
        if (data?.payload?.data?.length > 0) {
          setLoader(false);
          setNoDataMessage(""); // Clear message when data is returned
          addToast(
            infoToastConfig({
              toastMessage: "Data fetched successfully",
              variant: "success",
            })
          );
        } else {
          setLoader(false);

          setNoDataMessage("No data available"); // Set message when no data is returned
        }
      } else {
        setLoader(false);

        addToast(
          infoToastConfig({
            toastMessage: "API failed to fetch data",
            variant: "warning",
          })
        );
      }
    });
  }, [selectedValue, currentPage]);

  function checkAddMetaDataRole() {
    const role = sessionStorage.getItem('role');
    if (role === 'omt_tacct_bl_metadata_owner' || role === 'omt_tacct_admin' || role === 'omt_admin') {
      return true;
    }
    return false;
  }

  function checkAddSubcategoryRole() {
    const role = sessionStorage.getItem('role');
    if (role === 'omt_tacct_bl_metadata_requester' || role === 'omt_tacct_admin' || role === 'omt_admin') {
      return true;
    }
    return false;
  }

  function checkApprovalRole() {
    const role = sessionStorage.getItem('role');
    if (role === 'omt_tacct_admin' || role === 'omt_tacct_bl_approver_omni' || role === 'omt_tacct_bl_approver_taxccb' || role === 'omt_tacct_bl_metadata_requester' || role === 'omt_admin') {
      return true;
    }
    return false;
  }

  function checkEditCategoryRole() {
    const role = sessionStorage.getItem('role');
    if (role === 'omt_tacct_admin' || role === 'omt_tacct_bl_metadata_owner' || role === 'omt_admin') {
      return true;
    }
  }


  function AddSubButton() {
    window.location.href = '/tacct/add-subcategory-attribute'
  }

  // const downloadExcel = async () => {
  //   setCsvLoader(true);
  //   await dispatch(getEntriesToExport({
  //     status: statusSearch,
  //     country: countrySearch,
  //     cat_def: catOpSearch,
  //     sub_cat_def: subCatOpSearch,
  //     sub_cat: subCatSearch,
  //     cat: categorySearch,
  //     product: productSearch,
  //     group_purpose_code: isCode,
  //   })).then((data) => {
  //     const uncomressedDATA = data.payload.data;
  //     const decodedDATA = atob(uncomressedDATA);
  //     const charData = decodedDATA.split('').map(x => x.charCodeAt(0));
  //     const binData = new Uint8Array(charData);
  //     const dataStr = pako.inflate(binData, { to: 'string' });
  //     const data2 = JSON.parse(dataStr);

  //     setCsvLoader(false);
  //     ExportDataCSV(data2, "TACCT_BlueList")
  //   })

  // }


  function goToArchive() {
    window.location.href = '/tacct/bl-archive'
  }

  const pagination = (where) => {
    if (where === 'next') {
      setCurrentPage(currentPage + 1);
    } else {
      setCurrentPage(currentPage - 1);
    }
  }


  return (
    <>
      <HeaderNav msg={"Blue List Content Category & Subcategory"} />
      <LdsTile className='hero' >
        <div className="overview-container">
          <div className="overview-title">
            <span className='overview-text'>Overview</span>
            <div className='mt-3 options-medcom'>
              <LdsSelect
                value={selectedValue}
                onChange={(e) => handleChange(e)}
                options={options.map((option) => ({
                  value: option.value,
                  label: option.label.replace(/_/g, ' '),
                }))}
              />
            </div>
          </div>
          <div className=' button-container'>
            {
              checkAddSubcategoryRole() &&
              <LdsButton className=" buttons" onClick={AddSubButton}>Request New Sub Category</LdsButton>
            }
            {
              checkAddMetaDataRole() &&
              <LdsButton className=" buttons" onClick={() => setIsAddNewObjModalOpen(true)}>Add New Object</LdsButton>
            }
            {
              checkApprovalRole() &&
              <LdsButton className=" buttons" onClick={RfaButton}>{sessionStorage.getItem('role') === 'omt_tacct_bl_metadata_requester' ? 'Check Requested Entry' : 'Review Approval Requests'}</LdsButton>
            }
            {
              checkEditCategoryRole() &&
              <Link to="/tacct/edit-category">
                <LdsButton className=" buttons">Edit Object</LdsButton>
              </Link>
            }
            {
              checkEditCategoryRole() &&
              <Link to="/tacct/edit-product">
                <LdsButton className=" buttons">Edit Product</LdsButton>
              </Link>
            }

            <div className='second-row d-flex'>
              {!false ?
                <LdsButton className=" buttons" onClick={""} label="download-csv" icon="DownloadSimple">Download as CSV</LdsButton> :
                <div class='loader-csv'>
                  <> <LdsLoadingSpinner size={30} animationSpeed={500} /> &nbsp;</>
                </div>
              }
              <LdsButton className="buttons" onClick={""}>Search</LdsButton>
              <LdsButton className=" buttons" onClick={''}>Clear All</LdsButton>
              <LdsButton className=" buttons" onClick={goToArchive}>View Archive</LdsButton>
            </div>
          </div>

        </div>
      </LdsTile>


      {/* table here  */}
      {laoder ? <div className='loading-container-overview'> <LdsLoadingSpinner size={50} animationSpeed={500} /> </div> :
        <> <LdsTable className='container p-4' >
          <thead >
            <tr>
              {columns.map((col, index) => (
                <th className='ta-left' key={index} scope="col">{col.replace(/_/g, ' ').toUpperCase()}</th>
              ))}
            </tr>
          </thead>
          <tbody>
            {filteredData.map((row, rowIndex) => (
              <tr key={rowIndex} role="row">
                {columns.map((col, colIndex) => (
                  <td className='ta-left' key={colIndex} role="cell">
                    {row[col]}
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
        </LdsTable>
        <div className={noDataMessage ? "mt-extended p-5" : "p-5"}>
          <LdsPagination
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
            onNextClick={()=>(pagination('next'))}
            onPrevClick={()=>(pagination('prev'))}
            useButtons={true}
          />
          </div>
          {noDataMessage && <div className="loading-container-overview"><b>{noDataMessage}</b></div>} </>
      }
      <AddNewObjModal
        modalOpen={isAddNewObjModalOpen}
        setIsAddNewObjModalOpen={setIsAddNewObjModalOpen}

      />
      <HistoryModal
        modalOpen={isAuditModalOpen}
        setAuditIsModalOpen={setAuditIsModalOpen}
        tacticId={tacticId}
        sysName="tacct"
      />
      <div>
        <Chatbot fromPage={"Bluelist"} />
      </div>
    </>
  )
}