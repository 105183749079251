import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { getArchiveEntryRows } from '../../../api/actions/tacticentry';
import getArchiveEntriesToExport from '../../../api/actions/omms/getArchiveEntriesToExport';
import deleteArchiveEntry from '../../../api/actions/tacticentry/deleteArchiveEntry';

export const initialState = {
  loader: false,
  records: [],
  pageNumber: 1,
  pageSize: undefined,
  columnType: '',
  numberOfPages: '',
  deleteloader: false,
};

const fetchArchive = createAsyncThunk(
  "overview/fetchOverview",
  async (queryparams) => {
    const response = await getArchiveEntryRows(queryparams);
    return response.data.data;
  }
);

const deleteArchive = createAsyncThunk(
  "overview/deleteArchive",
  async (payload) => {
    const response = await deleteArchiveEntry(payload);
    return response.data;
  }
);

const exportArchiveEntries = createAsyncThunk(
  "getVendorData/exportArchiveEntries",
  async (queryParams) => {
    const response = await getArchiveEntriesToExport(queryParams);
    return await response.data;
  }
);

const archiveSlice = createSlice({
  name: 'archived',
  initialState,
  extraReducers(builder) {
    builder.addCase(fetchArchive.fulfilled, (state, action) => {
      state.loader = false;
      // state.records = action.payload.data;
      let temp = JSON.parse(JSON.stringify(action.payload.data));
      state.records = temp.map((obj) => {
        obj['check'] = false;
        // obj['toggleCheck'] = false;
        return obj
      });
      // state.pageNumber = action.payload;
      // state.pageSize = action.payload;
      // state.columnType = action.payload;
      state.numberOfPages = action.payload.numberOfPages;
    });
    builder.addCase(fetchArchive.pending, (state, action) => {
      state.loader = true;
    });
    builder.addCase(fetchArchive.rejected, (state, action) => {
      state.records = action.payload;
      state.loader = false;
      state.archive = action.payload;
      state.pageNumber = action.payload;
      state.pageSize = action.payload;
      state.columnType = action.payload;
      state.numberOfPages = action.payload;
    });
    builder.addCase(deleteArchive.pending, (state, action) => {
      state.deleteloader = true;
      state.error = null;
    });
    builder.addCase(deleteArchive.fulfilled, (state, action) => {
      state.deleteloader = false;
    });
    builder.addCase(deleteArchive.rejected, (state, action) => {
      state.deleteloader = false;
      state.error = action.payload;
    });
  },
});

export { fetchArchive,exportArchiveEntries, deleteArchive };

export default archiveSlice.reducer;
