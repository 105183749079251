import ctx from '../../../../plugins/axios';

export  async function getCategory ( queryParams) {
    try {
      const { data, status } = await ctx.get(`/bl-obj-ops?obj_name=${queryParams.obj_name}&obj_detail=${queryParams.obj_detail}&obj_type=${queryParams.obj_type}&pageSize=${queryParams.pageSize}&pageNumber=${queryParams.pageNumber}&tacctListVal=${queryParams.tacctListVal}&srcSysVal=${queryParams.srcSysVal}`);
      return { data, status };
    } catch (error) {
      return { error };
    }
  };
