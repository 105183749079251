import ctx from '../../../../plugins/axios';

export async function getcontentEntry (queryParams) {
  try {
    const { data, status } = await ctx.get(`/tacct-overview?tacctListVal=${queryParams.tacctListVal}&parentObjDetail=&parentObjType=&parentObjName=&sub_cat_def=&childObjType=&childObjName=&pageSize=${queryParams.pageSize}&pageNumber=${queryParams.pageNumber}&status=&childObjDetail=&prodParamVal&countryParamVal=`);
    return { data, status };
  } catch (error) {
    return { error };
  }
};


//this is archive api call 
export async function getcontentEntry2 (queryParams) {
  try {
    const { data, status } = await ctx.get(`/bl-overview?active_flag=${queryParams.active_flag}&pageSize=${queryParams.pageSize}&pageNumber=${queryParams.pageNumber}&group_purpose_code=${queryParams.group_purpose_code}`);
    return { data, status };
  } catch (error) {
    return { error };
  }
};