import ctx from '../../../plugins/axios';

export default async (queryParams) => {
  try {
    const { data, status } = await ctx.get(`/vendorentries/export?delivery_source=${queryParams.deliverySource}&delivery_source_type=${queryParams.deliverySourceType}&supplier_name=${queryParams.supplierName}&vehicle=${queryParams.vehicle}&contact_type=${queryParams.contactType}&platform=${queryParams.platform}&delivery_channel=${queryParams.deliveryChannel}&is_cap=${queryParams.is_cap}&role=${queryParams.role}&page=${queryParams.page}&push_or_pull_tactic=${queryParams.pushOrPullTactic}&tactic_depth=${queryParams.tacticDepth}`);
    return { data, status };
  } catch (error) {
    return { error };
  }
};
