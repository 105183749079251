import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import uploadCSV from '../../../api/actions/omms/uploadCSV';

export const initialState = {
  loader: false,
  records: [],
  pageNumber: 1,
  pageSize: undefined,
};

const uploadVendorEntries = createAsyncThunk(
  "/vendorentries",
  async (payload) => {
    const response = await uploadCSV(payload);
    if (response.error) {
      throw new Error(response.error.response);
    }
    return response.data;
  }
);

const uploadSlice = createSlice({
  name: 'uploadVendorEntries',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(uploadVendorEntries.fulfilled, (state, action) => {
      state.loader = false;
      state.records = action.payload;
    });
    builder.addCase(uploadVendorEntries.pending, (state) => {
      state.loader = true;
    });
    builder.addCase(uploadVendorEntries.rejected, (state, action) => {
      state.loader = false;
      state.error = action.error.message;
    });
  },
});

export { uploadVendorEntries };
export default uploadSlice.reducer;

