import ctx from '../../../plugins/axios';
// eslint-disable-next-line import/no-anonymous-default-export
export default async (payload) => {
  console.log(payload);
  try {
      const { data, status } = await ctx.post(`/vendorentries?type=upload`,payload);
      return { data, status };
 
  } catch (error) {
    return { error };
  }
};