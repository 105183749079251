import React, { useEffect, useState } from 'react'
import HeaderNav from '../../HeaderNav'
import { LdsButton, LdsLoadingSpinner, LdsPagination, LdsTile, useLdsModal, useToastContext } from '@elilillyco/ux-lds-react'
import ReusableTable from '../../shared/Table'
import { fetchBuData } from '../../../store/Components/TACCT/BU/buoverview'
import { useDispatch } from 'react-redux'
import AddBU from './AddBU';
import "../../../assets/components/TACCT/loader.scss"
import ExportDataCSV from '../../../data/utils/ExportDataCSV'
import Chatbot from '../../../pages/Chatbot'

const BusinessUnit = (props) => {
  const { isModalOpen: isAddBUModalOpen, setIsModalOpen: setIsAddBUModalOpen } = useLdsModal();

  function AddBusinessUnit() {
    window.location.href = '/tacct/business-unit/bussiness-unit-entry'
  }

  const infoToastConfig = {
    toastMessage: "Table has no data in it.",
    actionText: "",
    // actionCallback: () => console.log("View clicked"),
    variant: "informative",
    position: "top",
    align: "center",
    dismissible: true,
    light: false,
    timeout: 5000,
    inline: false,
    autoDismiss: true,
  };
  const { addToast } = useToastContext();

  const [design, setDesign] = useState()
  const [loader, setLoader] = useState()
  const dispatch = useDispatch();
  const page_type = 'overview'
  const page_size = 8
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const handleNext = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  }
  const handlePrev = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  }
  const page_number = currentPage
  const queryparams = { page_type, page_number, page_size }

  useEffect(() => {
    dispatch(fetchBuData(queryparams))
      .then((data) => (setLoader(true), data?.payload?.data?.budata[0] !== undefined ? setDesign(<><ReusableTable column={Object?.keys(data?.payload?.data?.budata[0]).slice(0, 3)} tableData={data?.payload?.data?.budata} status={true} disableEdit={true} /></>, setTotalPages(data?.payload?.data?.total_pages)) : addToast(infoToastConfig)))
  }, [page_number])

  function editData(data) {
    window.location.href = `/tacct/business-unit/bussiness-unit-entry?id=${data}`
  }

  function checkAddMetaDataRole() {
    const role = sessionStorage.getItem('role');
    if (role === 'omt_tacct_bl_metadata_requester' || role === 'omt_tacct_admin' || role === 'omt_admin') {
      return true;
    }
    return false;
  }

  function checkApprovalRole() {
    const role = sessionStorage.getItem('role');
    if (role === 'omt_tacct_bl_approver_taxccb' || role === 'omt_tacct_admin' || role === 'omt_tacct_bl_approver_omni' || role === 'omt_admin') {
      return true;
    }
    return false;
  }

  function ovButton() {
    window.location.href = '/tacct/business-unit/approval'
  }

  const downloadExcel = async () => {
    await dispatch(fetchBuData('overview')).then((data) => {
      ExportDataCSV(data?.payload.data, "TACCT_businessunit")
    })
  }
  const firstPage = () => {
    setCurrentPage(1)
  }
  const lastPage = () => {
    setCurrentPage(totalPages)
  }

  return (
    <>
      <HeaderNav msg={"Business Unit"} />
      <LdsTile className='hero container' >
        <div className="overview-title p-3">
          <span className='overview-text'>Overview</span>
        </div>
        <div className='button-container'>
          {/* {
            checkAddMetaDataRole() &&
            <LdsButton className="col buttons" onClick={AddBusinessUnit}>Request New Business Unit</LdsButton>
          }
          {
            checkApprovalRole() &&
            <LdsButton className="col buttons" onClick={ovButton} >Review Approval Requests</LdsButton>
          } */}
          <LdsButton className="col buttons" icon="DownloadSimple" label="download-csv" onClick={downloadExcel} >Download As CSV</LdsButton>
        </div>
      </LdsTile>

      <div className='container-md'>

        {loader ? <>
          {design}
          <div className="d-flex">
            <LdsButton className="compact outlined paginationLastFirstBtn" onClick={() => firstPage()}>First</LdsButton>

            <LdsPagination
              onNextClick={handleNext}
              onPrevClick={handlePrev}
              pageRangeVisible={8}
              totalPages={totalPages}
              currentPage={currentPage}
              setCurrentPage={setCurrentPage}
            />
            <LdsButton className="compact outlined paginationLastFirstBtn" onClick={() => lastPage()}>Last</LdsButton>
          </div>
        </>
          : <div className='spinner container loaderAlignFix'>
            <LdsLoadingSpinner size={90} />
          </div>}
      </div>
      <AddBU
        modalOpen={isAddBUModalOpen}
        setIsAddNewCatModalOpen={setIsAddBUModalOpen}
      />
      <div>
        <Chatbot fromPage={"Business Unit"} />
      </div>
    </>
  )
}

export default BusinessUnit