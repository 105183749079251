import React, { useState, useEffect } from "react";
import "../../../assets/components/TACCT/BL/AddEditVeevaVault.scss";
import HeaderNav from "../../HeaderNav";
import {
  LdsCheckbox,
  LdsIcon,
  LdsLink,
  LdsSelect,
  LdsCard,
  LdsButton,
  useLdsModal,
  LdsModal,
  LdsLoadingSpinner,
  LdsTextField,
} from "@elilillyco/ux-lds-react";
import { fetchCountries } from "../../../store/Components/TACCT/BL/tacctGetCountries";
import { fetchCategoryData } from "../../../store/Components/TACCT/BL/taccteditcategory";
import { useDispatch, useSelector } from "react-redux";
import { useToastContext } from "@elilillyco/ux-lds-react";

export default function AddEditVeevaVault() {
  const subcategory = useSelector(
    ({ tacctGetSubcategoryByID }) => tacctGetSubcategoryByID.Categories
  );
  const OptionList = useSelector(
    ({ tacctEditCatSlice }) => tacctEditCatSlice.getCategories
  );
  const OptionLoading = useSelector(
    ({ tacctEditCatSlice }) => tacctEditCatSlice.loading
  );
  const [checkedValue, setcheckedValue] = useState([]);
  const country = useSelector(
    ({ tacctCountries }) => tacctCountries.countryNumber
  );
  const countryLoading = useSelector(
    ({ tacctCountries }) => tacctCountries.loading
  );
  const URLParams = new URLSearchParams(window.location.search);
  const id = URLParams.get("id");
  const { isModalOpen: isSubmitModalOpen,setIsModalOpen: setSubmitIsModalOpen } = useLdsModal();
  const { isModalOpen: isDraftModalOpen, setIsModalOpen: setDraftIsModalOpen } = useLdsModal();
  const { addToast } = useToastContext();
  const toastConfig = (errTxtMsg, msgType) => {
    return {
      toastMessage: errTxtMsg,
      actionText: "",
      actionCallback: () => {},
      variant: msgType,
      position: "top",
      align: "center",
      dismissible: true,
      light: false,
      timeout: 5000,
      inline: false,
      autoDismiss: true,
    };
  };
  const validateFields = () => {
    if (checkedValue.length === 0) {
      addToast(toastConfig("Please select at least one country.", "warning"));
      return false;
    }
    const isCardInvalid = cards.some(
      (card) => !card.vvtyp || !card.vvsub || !card.vvcla
    );
    if (isCardInvalid) {
      addToast(toastConfig("Please fill all the values for VVTYP, VVSUB, and VVCLA.","warning"));
      return false;
    }
    return true;
  };

  const handleSubmit = () => {
    if (!validateFields()) {
      return;
    }
    setSubmitIsModalOpen(true);
  };

  const [countryArr, setcountryArr] = useState("");
  const catLoading = useSelector(
    ({ tacctGetSubcategoryByID }) => tacctGetSubcategoryByID.catLoading
  );
  const [countryList, setCountryList] = useState(country);
  useEffect(() => {
    setCountryList(country);
  }, [country]);

  const dispatch = useDispatch();
  useEffect(() => {
    const action = async () => {
      await dispatch(fetchCountries());
      await dispatch(
        fetchCategoryData({
          obj_name: "",
          obj_detail: "",
          obj_type: "VVSUB,VVTYP,VVCLA",
          tacctListVal: "VeevaVault_Doc_Types",
          srcSysVal: "",
        })
      );
    };
    action();
    // const URLParams = new URLSearchParams(window.location.search);
    // const id = URLParams.get("id");
  }, []);

  useEffect(() => {
    if (countryArr) {
      const arr = countryArr;
      const arrofnumber = arr.map((val) => parseInt(val));
      setcheckedValue([...arrofnumber]);
    }
  }, [countryArr]);

  useEffect(() => {
    setcountryArr(subcategory.country_id);
  }, [subcategory]);

  function handleMainChange(e) {
    if (e.target.checked) {
      setcheckedValue(countryList.map((obj) => obj.param_key_val_id));
    }
    if (!e.target.checked) {
      setcheckedValue([]);
    }
  }
  function checkedData(e) {
    if (e.target.checked) {
      if (!checkedValue.includes(parseInt(e.target.value))) {
        setcheckedValue([...checkedValue, parseInt(e.target.value)]);
      }
    }
    if (!e.target.checked) {
      if (checkedValue.includes(parseInt(e.target.value))) {
        setcheckedValue((prevcheckedVaue) =>
          checkedValue.filter((obj) => obj !== parseInt(e.target.value))
        );
      }
    }
  }

  function loaderFunction() {
    if (id) {
      if (catLoading && !countryLoading && !OptionLoading) {
        return true;
      } else return false;
    } else if (!countryLoading && !OptionLoading) {
      return true;
    } else return false;
  }

  const searchCountry = (e) => {
    e.target.value = e.target.value.toUpperCase();
    if (e.target.value !== "") {
      const cty = country.filter((obj) =>
        obj.param_val.includes(e.target.value.toUpperCase())
      );
      setCountryList(cty);
    }
    if (e.target.value === "") {
      setCountryList(country);
    }
  };

  const vvtypOptions = [
    { label: "Select VVTYP", value: "" },
    ...OptionList.filter((option) => option.obj_type === "VVTYP").map(
      (option) => ({
        label: option.obj_name,
        value: option.obj_name,
      })
    ),
  ];

  const vvsubOptions = [
    { label: "Select VVSUB", value: "" },
    ...OptionList.filter((option) => option.obj_type === "VVSUB").map(
      (option) => ({
        label: option.obj_name,
        value: option.obj_name,
      })
    ),
  ];

  const vvclaOptions = [
    { label: "Select VVCLA", value: "" },
    ...OptionList.filter((option) => option.obj_type === "VVCLA").map(
      (option) => ({
        label: option.obj_name,
        value: option.obj_name,
      })
    ),
  ];

  const [cards, setCards] = useState([
    { id: Date.now(), vvtyp: "", vvsub: "", vvcla: "" },
  ]);

  const handleAddCard = () => {
    setCards([...cards, { id: Date.now(), vvtyp: "", vvsub: "", vvcla: "" }]);
  };

  const handleDeleteCard = (id) => {
    setCards(cards.filter((card) => card.id !== id));
  };

  const handleCardChange = (id, field, value, detailField) => {
    const selectedOption = OptionList?.find(
      (option) =>
        option.obj_name === value && option.obj_type === field.toUpperCase()
    );

    setCards(
      cards.map((card) =>
        card.id === id
          ? {
              ...card,
              [field]: value,
              [detailField]: selectedOption ? selectedOption.obj_detail : "",
            }
          : card
      )
    );
  };

  const generatePayload = () => {
    // Generate payload logic here
    return {
      countries: checkedValue,
      cards: cards.map((card) => ({
        vvtyp_name: card.vvtyp,
        vvtyp_detail: card.vvtypDetail,
        vvsub_name: card.vvsub,
        vvsub_detail: card.vvsubDetail,
        vvcla_name: card.vvcla,
        vvcla_detail: card.vvclaDetail,
      })),
    };
  };

  return (
    <>
      <div>
        <HeaderNav msg="Blue List Content Category & Subcategory" />
      </div>
      <div className="redirect">
        <LdsLink href="/tacct/blue-list">
          <LdsIcon
            description="CaretLeft"
            label="search"
            name="CaretLeft"
            inline
          />
          Back to Overview
        </LdsLink>
      </div>
      <div class="container">
        <h2>{id ? "Edit" : "Add New"} Veeva Vault Doc</h2>
      </div>
      {loaderFunction() ? (
        <div className="container-add-as ">
          <div className="attributes">
            <div className="d-flex justify-content-between align-items-left flex-column">
              <p className="countryName">Country</p>
              <input
                type="text"
                placeholder="Search Countries"
                className="country-search"
                onChange={(e) => searchCountry(e)}
              />
            </div>
            &nbsp;
            <div className="SelectALl">
              <LdsCheckbox
                label="Select All"
                onClick={(e) => handleMainChange(e)}
              />
            </div>
            <div className="country">
              {countryList.map((entry, index) => (
                <div className="country-div">
                  {id == null && (
                    <LdsCheckbox
                      id={entry.param_key_val_id}
                      value={entry.param_key_val_id}
                      label={entry.param_val}
                      name="testCheckbox"
                      checked={checkedValue.includes(
                        parseInt(entry.param_key_val_id)
                      )}
                      onChange={(e) => {
                        checkedData(e);
                      }}
                    />
                  )}
                </div>
              ))}
            </div>
          </div>
          <div className="options">
            {cards.map((card) => (
              <LdsCard key={card.id} className="tabs">
                <div className="tab-row">
                  {/* VVTYP Section */}
                  <div className="row-item">
                    <LdsSelect
                      id={`VVTYPSelect-${card.id}`}
                      label="VVTYP"
                      name="VVTYP"
                      options={vvtypOptions}
                      // value={card?.vvtyp} // Pre-filled value
                      onChange={(e) =>
                        handleCardChange(
                          card.id,
                          "vvtyp",
                          e?.value,
                          "vvtypDetail"
                        )
                      }
                      errorMessage="Please select VVTYP"
                    />
                    <LdsTextField
                      id={`VVTYPTextField-${card.id}`}
                      label="VVTYP Detail"
                      value={card.vvtypDetail || ""} // Pre-filled detail
                      readonly
                      disabled
                    />
                  </div>

                  {/* VVSUB Section */}
                  <div className="row-item">
                    <LdsSelect
                      id={`VVSUBSelect-${card.id}`}
                      label="VVSUB"
                      name="VVSUB"
                      options={vvsubOptions}
                      // value={card?.vvsub} // Pre-filled value
                      onChange={(e) =>
                        handleCardChange(
                          card.id,
                          "vvsub",
                          e?.value,
                          "vvsubDetail"
                        )
                      }
                      errorMessage="Please select VVSUB"
                    />
                    <LdsTextField
                      id={`VVSUBTextField-${card.id}`}
                      label="VVSUB Detail"
                      value={card.vvsubDetail || ""} // Pre-filled detail
                      readonly
                      disabled
                    />
                  </div>

                  {/* VVCLA Section */}
                  <div className="row-item">
                    <LdsSelect
                      id={`VVCLASelect-${card.id}`}
                      label="VVCLA"
                      name="VVCLA"
                      options={vvclaOptions}
                      // value={card?.vvcla} // Pre-filled value
                      onChange={(e) =>
                        handleCardChange(
                          card.id,
                          "vvcla",
                          e?.value,
                          "vvclaDetail"
                        )
                      }
                      errorMessage="Please select VVCLA"
                    />
                    <LdsTextField
                      id={`VVCLATextField-${card.id}`}
                      label="VVCLA Detail"
                      value={card.vvclaDetail || ""} // Pre-filled detail
                      readonly
                      disabled
                    />
                  </div>

                  {/* Delete Button */}
                  <div className="delete">
                    {cards.length > 1 && (
                      <LdsIcon
                        onClick={() => handleDeleteCard(card.id)}
                        name="TrashSimpleFill"
                      />
                    )}
                  </div>
                </div>
              </LdsCard>
            ))}
            {!id &&     (
            <LdsButton
              onClick={handleAddCard}
              classes="system"
              className="add-button"
            ><LdsIcon name="PlusCircleFill" className="add-icon" />
              Add More
            </LdsButton>)}
            <div className="save">
              <LdsButton
                classes="outlined"
                onClick={() => setDraftIsModalOpen(true)}
              >
                Save as Draft
              </LdsButton>
              <LdsButton onClick={handleSubmit}> Submit Request </LdsButton>
              <div></div>
            </div>
            <div className="save">
              <LdsModal
                modalId="testModal"
                open={isDraftModalOpen}
                setModalOpen={setDraftIsModalOpen}
                heading={"Are you sure you want to draft this request ?"}
              >
                <div className="space">
                  <LdsButton
                    onClick={(e) => {
                      const payload = generatePayload();
                      console.log("Payload:", payload);
                      // Send the payload to the backend or handle it as needed
                    }}
                  >
                    Yes
                  </LdsButton>
                  <LdsButton onClick={(e) => setDraftIsModalOpen(false)}>
                    No
                  </LdsButton>
                </div>
              </LdsModal>
              <LdsModal
                modalId="testModal"
                open={isSubmitModalOpen}
                setModalOpen={setSubmitIsModalOpen}
                heading={"Are you sure you want to request for approval?"}
              >
                <div className="space">
                  <LdsButton onClick={(e) => ""}>Yes</LdsButton>
                  <LdsButton onClick={(e) => setSubmitIsModalOpen(false)}>
                    No
                  </LdsButton>
                </div>
              </LdsModal>
            </div>
          </div>
        </div>
      ) : (
        <LdsLoadingSpinner size={70} class="spinner-add" />
      )}
    </>
  );
}
