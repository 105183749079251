import { LdsIcon, LdsTable } from '@elilillyco/ux-lds-react'
import React, { useEffect, useState } from 'react'


const ReusableTable = (props) => {
    const disableEdit = props?.disableEdit
    const editData = props?.editData
    const column = props?.column
    const value = props?.tableData.map((data) => Object?.values(data))
    const status = props?.status
    const [getSliceConfig, setSliceConfig] = useState(false)

    useEffect(() => {
        setSliceConfig(status)
    }, [])
    function checkRole() {
        const role = sessionStorage.getItem('role');
        if (role === 'omt_tacct_bl_metadata_requester' || role === 'omt_tacct_admin' || role === 'omt_admin') {
            return true;
        }
        return false;
    }

    return (
        <>
            <LdsTable className='common-table'>
                <thead>
                    <tr>
                        {column.map((data) =>
                            <th className="ta-left" scope="col">
                                {data.replace(/_/g, ' ').toUpperCase()}
                            </th>
                        )}
                        {checkRole() && !disableEdit ? <th className="ta-left" scope="col">
                            EDIT
                        </th> : ''}
                    </tr>
                </thead>
                {value && value?.length > 0 ?
                    <tbody>
                        {value.map((data, index) =>
                            <tr role="row" key={index}
                                style={(data[0] === 'REJECTED' || data[0] === 'RETIRED') ? { backgroundColor: 'rgb(241, 184, 184)' } : {}}
                            >
                                {data.slice(0, 3).map((val) =>
                                    <td className='table ta-left'>{val}</td>)
                                }
                                {checkRole() && !disableEdit ? <td className='ta-left' ><LdsIcon style={{ display: 'inline' }} onClick={() => (editData(data[3]))} label="Edit" className=" table-icons" name='PencilSimpleLineFill' /> </td> : ''}
                            </tr>
                        )}
                    </tbody> : (<tbody>
                        <div>
                            <span className='no-data'>No Data Available</span>
                        </div>
                    </tbody>
                    )
                }
            </LdsTable>
            
        </>
    )
}

export default ReusableTable