import React, { useEffect, useRef } from 'react'
import {
  LdsTile, LdsTable, LdsCheckbox, LdsIcon, LdsButton, LdsPagination, LdsTextField
  , LdsLoadingSpinner,
  LdsToast, LdsTooltip,
} from "@elilillyco/ux-lds-react";
import { useState } from 'react';
import { LdsModal, useLdsModal, useToastContext } from "@elilillyco/ux-lds-react";
import HeaderNav from '../HeaderNav';
import "../../assets/components/OMMS/overview.scss"
import { fetchCommonTactic } from '../../store/Components/OMMS/commonTactic';
import { fetchVendorData, ExportVendorData } from '../../store/Components/OMMS/overview';
import { useDispatch, useSelector } from 'react-redux';
import { updateArchive } from '../../store/Components/OMMS/archiveputStore';
import HistoryModal from '../shared/HistoryModal';
import ExportDataCSV from '../../data/utils/ExportDataCSV';

export default function Overview(props) {
  const [statusSearchFld1, setIsON1] = React.useState(false);
  const [statusSearchFld2, setIsON2] = React.useState(false);
  const [statusSearchFld3, setIsON3] = React.useState(false);
  const [statusSearchFld4, setIsON4] = React.useState(false);
  const [statusSearchFld5, setIsON5] = React.useState(false);
  const [statusSearchFld6, setIsON6] = React.useState(false);
  const [statusSearchFld7, setIsON7] = React.useState(false);
  const [statusSearchFld8, setIsON8] = React.useState(false);
  const [statusSearchFld9, setIsON9] = React.useState(false);
  const [statusSearchFld10, setIsON10] = React.useState(false);
  const [statusSearchFld11, setIsON11] = React.useState(false);
  const [statusSearchFld12, setIsON12] = React.useState(false);
  const [statusSearchFld13, setIsON13] = React.useState(false);
  const [statusSearchFld14, setIsON14] = React.useState(false);
  const [statusSearchFld15, setIsON15] = React.useState(false);
  const [statusSearchFld16, setIsON16] = React.useState(false);
  const [statusSearchFld17, setIsON17] = React.useState(false);


  const loader = useSelector(({ overview }) => overview.loader);

  const data = useSelector(({ overview }) => overview.vendorData)
  const tPages = useSelector(({ overview }) => overview.totalPages)
  const role2 = useSelector(({ user }) => user.role_ids);

  const { isModalOpen, setIsModalOpen } = useLdsModal();
  const { addToast } = useToastContext();
  const { isModalOpen: isAuditModalOpen, setIsModalOpen: setAuditIsModalOpen } = useLdsModal();

  const defaultRole = useSelector(({ user }) => user.defaultRole)

  const dispatch = useDispatch();
  const [colName, setColName] = useState('')
  const [searchKey, setSearchKey] = useState('')

  let cData = useSelector(({ commonTacticSlice }) => commonTacticSlice.commonData)
  const [commonData, setcommonData] = useState()
  const commonLoader = useSelector(({ commonTacticSlice }) => commonTacticSlice.commonLoader)
  const [curTacticId, setCurTacticId] = useState(null);
  const [isData, setIsData] = useState([...data]);
  const [isChecked, setIsChecked] = useState(false);
  const [see, setSee] = useState(false)
  const [getId, setId] = useState([]);
  const [name, setName] = useState('')
  useEffect(() => {
    setcommonData(cData)
  }, [cData])

  const [deliverySource, setDeliverySource] = useState('')
  const [supplierName, setSupplierName] = useState('')
  const [deliverySourceType, setDeliverySourceType] = useState('')
  const [deliveryChannel, setDeliveryChannel] = useState('')
  const [platform, setPlatform] = useState('')
  const [contactType, setContactType] = useState('')


  useEffect(() => {
    dispatch(fetchVendorData({
      pageNumber: 1,
      pageSize: 8,
      deliverySource: deliverySource,
      deliveryChannel: deliveryChannel,
      deliverySourceType: deliverySourceType,
      supplierName: supplierName,
      platform: platform,
      contactType: contactType,
      role: defaultRole,
      page: 'overview',
    }))
  }, [])
  useEffect(() => {
    setIsData([...data]);
  }, [data]);



  function searchFld() {
    dispatch(fetchVendorData({
      pageNumber: 1,
      pageSize: 8,
      deliverySource: deliverySource.trim(),
      deliveryChannel: deliveryChannel.trim(),
      deliverySourceType: deliverySourceType.trim(),
      supplierName: supplierName.trim(),
      platform: platform.trim(),
      contactType: contactType.trim(),
      role: defaultRole.trim(),
      page: 'overview',
    }));
  }
  function editExistingData(parValues) {
    console.log(parValues.aStatus)
    window.location.href = `/omms/tactic-entry?delivery_source=${parValues.dSource}&delivery_source_type=${parValues.dSType}&platform=${parValues.platform}&supplier_name=${parValues.sName}&delivery_channel=${parValues.dChannel}&contact_type=${parValues.cType}&is_cap=${parValues.iscap}&is_rm=${parValues.isRm}&is_pld=${parValues.isPld}&is_digital=${parValues.isDigital}&approval_status=${parValues.aStatus}`
  }
  const [lastEvent, setLastEvent] = useState('');
  const totalPages = tPages
  const [currentPage, setCurrentPage] = useState(1);
  const records = data

  const clickLink = (e, value) => {
    setLastEvent(`clickLink, value: ${value}`);
    dispatch(fetchVendorData({
      pageNumber: value,
      pageSize: 8,
      deliverySource: deliverySource,
      deliveryChannel: deliveryChannel,
      deliverySourceType: deliverySourceType,
      supplierName: supplierName,
      platform: platform,
      contactType: contactType,
      role: defaultRole,
      page: 'overview',
    }));

  };
  const clickNext = (e, value) => {
    setLastEvent(`clickNext, value: ${value}`);
    dispatch(fetchVendorData({
      pageNumber: currentPage + 1,
      pageSize: 8,
      deliverySource: deliverySource,
      deliveryChannel: deliveryChannel,
      deliverySourceType: deliverySourceType,
      supplierName: supplierName,
      platform: platform,
      contactType: contactType,
      role: defaultRole,
      page: 'overview',
    }));
  };

  const clickPrev = (e, value) => {
    setLastEvent(`clickPrev, value: ${value}`);
    dispatch(fetchVendorData({
      pageNumber: currentPage + 1,
      pageSize: 8,
      deliverySource: deliverySource,
      deliveryChannel: deliveryChannel,
      deliverySourceType: deliverySourceType,
      supplierName: supplierName,
      platform: platform,
      contactType: contactType,
      role: defaultRole,
      page: 'overview',
    }));
  };


  function checkMetaDataRole() {
    const role = sessionStorage.getItem('role');
    if (role === "omms_admin" || role === "omt_admin" || role === "omms_metadata_manager") {
      return true;
    }
    return false;
  }

  function checkTacticRole() {
    const role = sessionStorage.getItem('role');
    if (role === "omms_admin" || role === "omt_admin" || role === "omms_vendor" || role === "omms_metadata_manager" || role === "omms_peer_review_bia" || role === "omms_peer_review_channel" || role === "omms_peer_review_campaign") {
      return true;
    }
    return false;
  }


  function checkApprovalRole() {
    const role = sessionStorage.getItem('role');
    if (role === "omms_admin" || role === "omms_peer_review_bia" || role === "omms_peer_review_channel" || role === "omms_peer_review_campaign" || role === "omt_admin") {
      return true;
    }
    return false;
  }

  function checkArchiveRole() {
    const role = sessionStorage.getItem('role');
    if (role === "omms_admin" || role === "omt_admin") {
      return true;
    }
  }

  function checkExceptionRole() {
    const role = sessionStorage.getItem('role');
    if (role === "omms_admin" || role === "omms_peer_review_bia" || role === "omt_admin") {
      return true;
    }
  }
  function checkEditRole() {
    const role = sessionStorage.getItem('role');
    if (role === "omms_admin" || role === "omt_admin" || role === "omms_vendor" || role === "omms_metadata_manager" || role === "omms_peer_review_bia" || role === "omms_peer_review_channel" || role === "omms_peer_review_campaign") {
      return true;
    }
  }


  function addtactic() {
    window.location.href = '/omms/tactic-entry';
  }
  function addmetadata() {
    window.location.href = '/omms/add-metadata';
  }
  function addEditMetadata() {
    window.location.href = '/omms/add-edit-metadata';
  }
  function archive() {
    window.location.href = '/omms/archive';
  }
  function reqforapproval() {
    window.location.href = '/omms/requestforapproval';
  }
  function exceptionReporting() {
    window.location.href = '/omms/exception-reporting';
  }
  function handleClick() {
    setIsModalOpen(false)
  }

  function modalOpen() {
    if (getId.length === 0) {
      addToast({
        toastMessage: "Please select atleast one record ",
        actionText: "",
        variant: "warning",
        position: "top",
        align: "center",
        dismissible: true,
        light: false,
        timeout: 5000,
        inline: false,
        autoDismiss: true,
        zIndex: 11
      })
      return;
    }
    setIsModalOpen(true)
  }

  const downloadExcel = () => {
    dispatch(ExportVendorData({
      deliverySource: deliverySource,
      deliveryChannel: deliveryChannel,
      deliverySourceType: deliverySourceType,
      supplierName: supplierName,
      platform: platform,
      contactType: contactType,
      columnType: colName,
      role: defaultRole,
      page: 'overview',
    })).then((data) => {
      ExportDataCSV(data.payload.data, "OMMS_CampaignList")
    })
  }

  function toArchivePage() {
    const currentRole = sessionStorage.getItem('role');

    const currentUserData = role2.filter((roleData) => Object.keys(roleData)[0] === currentRole);
    const updated_by = currentUserData[0][currentRole];



    dispatch(updateArchive({ 'omms_overview_detail_id': getId, status: "active", updated_by }))
    dispatch(fetchVendorData({
      pageNumber: 1,
      pageSize: 8,
      deliverySource: deliverySource,
      deliveryChannel: deliveryChannel,
      deliverySourceType: deliverySourceType,
      supplierName: supplierName,
      platform: platform,
      contactType: contactType,
      role: defaultRole,
      page: 'overview',
    }))

  }


  function handleMainChange(e) {
    if (e.target.checked) {
      setId(commonData.map(obj => obj.omms_overview_detail_id))
    }
    if (!e.target.checked) {
      setId([])
    }
  }

  const checkedData = (e) => {
    if (e.target.checked) {
      if (!getId.includes(parseInt(e.target.value))) {
        setId([...getId, parseInt(e.target.value)])
      }
    }
    if (!e.target.checked) {
      if (getId.includes(parseInt(e.target.value))) {
        setId(getId.filter((obj) => obj !== parseInt(e.target.value)))
      }
    }
  }


  const [srchkey, setSrchKey] = useState({
    metricLabel: '',
    engagementLabel: '',
    hierarchyClassification: '',
    metricDefinition: ''
  })
  const searchOP = (cData) => {
    const filteredData = cData.filter(item => {
      return Object.keys(srchkey).every(key => {
        // If the search field is empty, return true (don't filter it out)
        if (srchkey[key] === '') return true;
        // Split the search string into an array of values
        const searchValues = srchkey[key].toUpperCase().split(',');
        // If the data point includes any of the search values, return true (don't filter it out)
        return item[key] && searchValues.some(value => item[key].toString().toUpperCase().includes(value.trim()));
      });
    });

    setcommonData(filteredData);
  }



  const clearSub = () => {
    setIsON6(false)
    setIsON5(false)
    setIsON7(false)
    setIsON8(false)
    setIsON9(false)
    setIsON10(false)
    setIsON11(false)
    setIsON12(false)
    setIsON13(false)
    setIsON14(false)
    setIsON15(false)
    setIsON16(false)
    setIsON17(false)
    setcommonData(cData)
  }

  const [parValues, setParValues] = useState({})
  const commonvalueCheck = (params) => {
    console.log(params.aStatus, 'astat')
    dispatch(fetchCommonTactic({
      delivery_source: params.dSource,
      supplier_name: params.sName,
      delivery_source_type: params.dSType,
      delivery_channel: params.dChannel,
      platform: params.platform,
      contact_type: params.cType,
      approval_status: params.aStatus,
      iscap: params.iscap === 'yes' ? true : false,
    }))
    setParValues({ ...params })
    console.log(parValues, 'pval')
  }

  const clearSearch = () => {
    setIsON1(false)
    setIsON2(false)
    setIsON3(false)
    setIsON4(false)
    setIsON5(false)
    setIsON6(false)
    setIsON7(false)
    setIsON8(false)
    setIsON9(false)
    setIsON10(false)
    setIsON11(false)
    setDeliverySource('')
    setDeliveryChannel('')
    setSupplierName('')
    setContactType('')
    setPlatform('')
    setDeliverySourceType('')
    setCurrentPage(1)
    dispatch(fetchVendorData({
      pageNumber: 1,
      pageSize: 8,
      deliverySource: '',
      deliveryChannel: '',
      deliverySourceType: '',
      supplierName: '',
      platform: '',
      contactType: '',
      role: defaultRole,
      page: 'overview',
    }))
  }

  return (
    <>

      <HeaderNav msg={"Read Only (OMMS Tool is Under Maintainance)"} />
      <LdsTile className='overview-hero' >
        <div className="overview-container">
          <div className="overview-title">
            <span className='overview-text'>Overview</span>

          </div>

          <div className='button-container-ov'>
            <LdsButton onClick={clearSearch} className='col buttons' >Clear Search</LdsButton>
            <LdsButton icon="DownloadSimple" className='col buttons' disabled>Download</LdsButton>
            {/* {checkMetaDataRole() && <LdsButton onClick = {addmetadata} className='col buttons'>Add Metadata</LdsButton>} */}
            {checkMetaDataRole() && <LdsButton className='col buttons' disabled>Add/Edit Metadata</LdsButton>}
            {checkTacticRole() && <LdsButton className='col buttons' disabled>Add Tactic</LdsButton>}
            {checkApprovalRole() && <LdsButton className='col buttons' disabled>Request For Approval</LdsButton>}
            {checkArchiveRole() && <LdsButton className='col buttons' disabled>View Archive</LdsButton>}
            {checkExceptionRole() && <LdsButton className='col buttons' disabled>Exception Reporting</LdsButton>}
          </div></div>
      </LdsTile>
      <LdsModal
        modalId="Sub"
        open={see}
        setModalOpen={setSee}
        heading="Metric Table"
        style={{ zIndex: 100 }}
      >

        <div className='parent-container'>
          <div className='parent-row'>
            <span><span className='parent-h'>DELIVERY SOURCE: </span>{parValues.dSource}</span>
            <span><span className='parent-h'>Supplier Name: </span> {parValues.sName}</span>
          </div>
          <div className='parent-row'>
            <span><span className='parent-h'>Platform: </span>{parValues.platform}</span>
            <span><span className='parent-h'>Contact Type: </span>{parValues.cType}</span>
          </div>
          <div className='parent-row'>
            <span><span className='parent-h'>Delivery Channel: </span> {parValues.dChannel}</span>
            <span><span className='parent-h'>Delivery Source Type: </span> {parValues.dSType}</span>
          </div>
          <div className='parent-row'>
            {checkEditRole() && <LdsButton className='sub-icon' name='PencilSimpleLineFill' label='edit' onClick={() => { editExistingData(parValues) }} disabled>Edit</LdsButton>}
            <LdsButton className='sub-icon' name='PencilSimpleLineFill' label='edit' onClick={() => { clearSub() }} >Clear</LdsButton>
          </div>
        </div>
        <LdsTable className='sub-table mt-4 mb-3 '>
          <thead>
            <tr>
              {checkArchiveRole() && <th className="table-ends" scope="col">
                <LdsCheckbox

                  name="opt1"
                  onChange={(e) => { handleMainChange(e) }}
                  style={{ marginTop: '-1.5rem' }}
                />
              </th>}
              <th scope="col" className='table-ends'>
                <div class="attriDiv">
                  Metric Label
                  <div class="iconStyle" style={{ display: 'flex', alignItems: 'initial', maxHeight: '1rem', marginTop: '0.3rem' }}>
                    <LdsIcon
                      description="MagnifyingGlass"
                      label="search"
                      name="MagnifyingGlass"
                      class="icon-size"
                      style={{
                        height: '15px',
                        marginBottom: '-2rem',
                        maxWidth: '2.5rem',
                        minWidth: '2.5rem'
                      }}
                      onClick={() => {
                        setIsON6(!statusSearchFld6)
                      }}

                    />
                  </div>
                </div>
                {statusSearchFld6 &&
                  <div style={{ width: '12rem' }}>
                    <LdsTextField
                      className='text-fld'
                      id='metricLabel'
                      onChange={(e) => setSrchKey({ ...srchkey, [e.target.id]: e.target.value })}
                      onKeyDown={(e) => { if (e.key === "Enter") { searchOP(cData) } }}
                    />
                  </div>
                }
              </th>
              <th scope="col" className='table-ends'>
                <div class="attriDiv">
                  Engagement Classification
                  <div class="iconStyle" style={{ display: 'flex', alignItems: 'initial', maxHeight: '1rem', marginTop: '0.3rem' }}>
                    <LdsIcon
                      description="MagnifyingGlass"
                      label="search"
                      name="MagnifyingGlass"
                      class="icon-size"
                      style={{
                        height: '15px',
                        marginBottom: '-2rem',
                        maxWidth: '2.5rem',
                        minWidth: '2.5rem'
                      }}
                      onClick={() => { setIsON5(!statusSearchFld5) }}
                    />
                  </div>
                </div>
                {statusSearchFld5 &&
                  <div style={{ width: '12rem' }}>
                    <LdsTextField
                      className='text-fld'
                      id='engagementClassification'
                      onChange={(e) => setSrchKey({ ...srchkey, [e.target.id]: e.target.value.replace(/\s*,\s*/g, ',').replace(/\s+/g, ' ') })}
                      onKeyDown={(e) => { if (e.key === "Enter") { searchOP(cData) } }}
                    />
                  </div>
                }
              </th>
              <th scope="col" className='table-ends'>
                <div class="attriDiv">
                  Hierarchy Classification
                  <div class="iconStyle" style={{ display: 'flex', alignItems: 'initial', maxHeight: '1rem', marginTop: '0.3rem' }}>
                    <LdsIcon
                      description="MagnifyingGlass"
                      label="search"
                      name="MagnifyingGlass"
                      class="icon-size"
                      style={{
                        height: '15px',
                        marginBottom: '-2rem',
                        maxWidth: '2.5rem',
                        minWidth: '2.5rem'
                      }}
                      onClick={() => { setIsON7(!statusSearchFld7) }}
                    />
                  </div>
                </div>
                {statusSearchFld7 &&
                  <div style={{ width: '12rem' }}>
                    <LdsTextField
                      className='text-fld'
                      id='hierarchyClassification'
                      onChange={(e) => setSrchKey({ ...srchkey, [e.target.id]: e.target.value.replace(/\s*,\s*/g, ',').replace(/\s+/g, ' ') })}
                      onKeyDown={(e) => { if (e.key === "Enter") { searchOP(cData) } }}
                    />
                  </div>
                }
              </th>
              <th scope="col" className='table-ends'>
                <div class="attriDiv">
                  Metric Definition
                  <div class="iconStyle" style={{ display: 'flex', alignItems: 'initial', maxHeight: '1rem', marginTop: '0.3rem' }}>
                    <LdsIcon
                      description="MagnifyingGlass"
                      label="search"
                      name="MagnifyingGlass"
                      class="icon-size"
                      style={{
                        height: '15px',
                        marginBottom: '-2rem',
                        maxWidth: '2.5rem',
                        minWidth: '2.5rem'
                      }}
                      onClick={() => { setIsON10(!statusSearchFld10) }}
                    />
                  </div>
                </div>
                {statusSearchFld10 &&
                  <div style={{ width: '12rem' }}>
                    <LdsTextField
                      className='text-fld'
                      id='metricDefinition'
                      onKeyDown={(e) => { if (e.key === "Enter") { searchOP(cData) } }}
                    />
                  </div>
                }
              </th>
              <th scope="col" className='table-ends'>
                <div class="attriDiv">
                  Engagement Depth
                  <div class="iconStyle" style={{ display: 'flex', alignItems: 'initial', maxHeight: '1rem', marginTop: '0.3rem' }}>
                    <LdsIcon
                      description="MagnifyingGlass"
                      label="search"
                      name="MagnifyingGlass"
                      class="icon-size"
                      style={{
                        height: '15px',
                        marginBottom: '-2rem',
                        maxWidth: '2.5rem',
                        minWidth: '2.5rem'
                      }}
                      onClick={() => { setIsON12(!statusSearchFld12) }}

                    />
                  </div>
                </div>
                {statusSearchFld12 &&
                  <div style={{ width: '12rem' }}>
                    <LdsTextField
                      className='text-fld'
                      id='engagementDepth'
                      onChange={(e) => setSrchKey({ ...srchkey, [e.target.id]: e.target.value.replace(/\s*,\s*/g, ',').replace(/\s+/g, ' ') })}
                      onKeyDown={(e) => { if (e.key === "Enter") { searchOP(cData) } }}
                    />
                  </div>
                }
              </th>
              <th scope="col" className='table-ends'>
                <div class="attriDiv">
                  Contract Basis Classification
                  <div class="iconStyle" style={{ display: 'flex', alignItems: 'initial', maxHeight: '1rem', marginTop: '0.3rem' }}>
                    <LdsIcon
                      description="MagnifyingGlass"
                      label="search"
                      name="MagnifyingGlass"
                      class="icon-size"
                      style={{
                        height: '15px',
                        marginBottom: '-2rem',
                        maxWidth: '2.5rem',
                        minWidth: '2.5rem'
                      }}
                      onClick={() => { setIsON13(!statusSearchFld13) }}

                    />
                  </div>
                </div>
                {statusSearchFld13 &&
                  <div style={{ width: '12rem' }}>
                    <LdsTextField
                      className='text-fld'
                      id='contractBasisClassification'
                      onChange={(e) => setSrchKey({ ...srchkey, [e.target.id]: e.target.value.replace(/\s*,\s*/g, ',').replace(/\s+/g, ' ') })}
                      onKeyDown={(e) => { if (e.key === "Enter") { searchOP(cData) } }}
                    />
                  </div>
                }
              </th>
              <th scope="col" className='table-ends'>
                <div class="attriDiv">
                  Performance Basis Classification
                  <div class="iconStyle" style={{ display: 'flex', alignItems: 'initial', maxHeight: '1rem', marginTop: '0.3rem' }}>
                    <LdsIcon
                      description="MagnifyingGlass"
                      label="search"
                      name="MagnifyingGlass"
                      class="icon-size"
                      style={{
                        height: '15px',
                        marginBottom: '-2rem',
                        maxWidth: '2.5rem',
                        minWidth: '2.5rem'
                      }}
                      onClick={() => { setIsON14(!statusSearchFld14) }}

                    />
                  </div>
                </div>
                {statusSearchFld14 &&
                  <div style={{ width: '12rem' }}>
                    <LdsTextField
                      className='text-fld'
                      id='performanceBasisClassification'
                      onChange={(e) => setSrchKey({ ...srchkey, [e.target.id]: e.target.value.replace(/\s*,\s*/g, ',').replace(/\s+/g, ' ') })}
                      onKeyDown={(e) => { if (e.key === "Enter") { searchOP(cData) } }}
                    />
                  </div>
                }
              </th>
              <th scope="col" className='table-ends'>
                <div class="attriDiv">
                  Engagement Rate Calculated Against
                  <div class="iconStyle" style={{ display: 'flex', alignItems: 'initial', maxHeight: '1rem', marginTop: '0.3rem' }}>
                    <LdsIcon
                      description="MagnifyingGlass"
                      label="search"
                      name="MagnifyingGlass"
                      class="icon-size"
                      style={{
                        height: '15px',
                        marginBottom: '-2rem',
                        maxWidth: '2.5rem',
                        minWidth: '2.5rem'
                      }}
                      onClick={() => { setIsON15(!statusSearchFld15) }}

                    />
                  </div>
                </div>
                {statusSearchFld15 &&
                  <div style={{ width: '12rem' }}>
                    <LdsTextField
                      className='text-fld'
                      id='engagementRateCalculatedAgainst'
                      onChange={(e) => setSrchKey({ ...srchkey, [e.target.id]: e.target.value.replace(/\s*,\s*/g, ',').replace(/\s+/g, ' ') })}
                      onKeyDown={(e) => { if (e.key === "Enter") { searchOP(cData) } }}
                    />
                  </div>
                }
              </th>
              <th scope="col" className="table-ends">
              <div class="attriDivCore">
                Engagement Based Tactic
                <div
                  class="iconStyle"
                  style={{
                    display: "flex",
                    alignItems: "initial",
                    maxHeight: "1rem",
                    marginTop: "0.3rem",
                  }}
                >
                  <LdsIcon
                    description="MagnifyingGlass"
                    label="search"
                    name="MagnifyingGlass"
                    class="icon-size"
                    style={{
                      height: "15px",
                      marginBottom: "-2rem",
                      maxWidth: "2.5rem",
                      minWidth: "2.5rem",
                    }}
                    onClick={() => {
                      setIsON16(!statusSearchFld16);
                    }}
                  />
                </div>
              </div>
              {statusSearchFld16 && (
                <div style={{ width: "12rem" }}>
                  <LdsTextField
                    className="text-fld"
                    id="orgName"
                    onChange={(e) => setSrchKey({ ...srchkey, [e.target.id]: e.target.value.replace(/\s*,\s*/g, ',').replace(/\s+/g, ' ') })}
                    onKeyDown={(e) => { if (e.key === "Enter") { searchOP(cData) } }}
                  />
                </div>
              )}
              </th>
              <th scope="col" className="table-ends">
              <div class="attriDivCore">
                Vehicle
                <div
                  class="iconStyle"
                  style={{
                    display: "flex",
                    alignItems: "initial",
                    maxHeight: "1rem",
                    marginTop: "0.3rem",
                  }}
                >
                  <LdsIcon
                    description="MagnifyingGlass"
                    label="search"
                    name="MagnifyingGlass"
                    class="icon-size"
                    style={{
                      height: "15px",
                      marginBottom: "-2rem",
                      maxWidth: "2.5rem",
                      minWidth: "2.5rem",
                    }}
                    onClick={() => {setIsON17(!statusSearchFld17);
                    }}
                  />
                </div>
              </div>
              {statusSearchFld17 && (
                <div style={{ width: "12rem" }}>
                  <LdsTextField
                    className="text-fld"
                    id="orgName"
                    onChange={(e) => setSrchKey({ ...srchkey, [e.target.id]: e.target.value.replace(/\s*,\s*/g, ',').replace(/\s+/g, ' ') })}
                    onKeyDown={(e) => { if (e.key === "Enter") { searchOP(cData) } }}
                  />
                </div>
              )}
              </th>


              <th scope="col" className='table-ends'>
                <div class="attriDiv">
                  Status
                  <div class="iconStyle" style={{ display: 'flex', alignItems: 'initial', maxHeight: '1rem', marginTop: '0.3rem' }}>
                    <LdsIcon
                      description="MagnifyingGlass"
                      label="search"
                      name="MagnifyingGlass"
                      class="icon-size"
                      style={{
                        height: '15px',
                        marginBottom: '-2rem',
                        maxWidth: '2.5rem',
                        minWidth: '2.5rem'
                      }}
                      onClick={() => { setIsON11(!statusSearchFld11) }}
                    />
                  </div>
                </div>
                {statusSearchFld11 &&
                  <div style={{ width: '12rem' }}>
                    <LdsTextField
                      className='text-fld'
                      id='status'
                      onChange={(e) => setSrchKey({ ...srchkey, [e.target.id]: e.target.value.replace(/\s*,\s*/g, ',').replace(/\s+/g, ' ') })}
                      onKeyDown={(e) => { if (e.key === "Enter") { searchOP(cData) } }}
                    />
                  </div>
                }
              </th>

              <th className="table-ends" scope="col">History</th>
            </tr>
          </thead>
          {commonLoader === false ? (commonData && commonData.length > 0 ?
            <tbody>
              {commonData.map((entry, rowIndex) =>
                <tr role="row" key={rowIndex}>
                  {checkArchiveRole() && <td className="table-ends">
                    <LdsCheckbox name="opt1"
                      id={entry.omms_overview_detail_id}
                      onChange={(e) => { checkedData(e) }}
                      value={entry.omms_overview_detail_id}
                      checked={getId.includes(parseInt(entry.omms_overview_detail_id))} />
                  </td>}
                  <td className='table-ends'>{entry.metricLabel}</td>
                  <td className='table-ends'>{entry.engagementClassification}</td>
                  <td className='table-ends'>{entry.hierarchyClassification}</td>
                  <td className='table-ends'>{entry.metricDefinition}</td>
                  <td className='table-ends'>{entry.engagementDepth}</td>
                  <td className='table-ends'>{entry.contractBasisClassification}</td>
                  <td className='table-ends'>{entry.performanceBasisClassification}</td>
                  <td className='table-ends'>{entry.engagementRateCalculatedAgainst}</td>

                  <td className='table-ends'>{entry.approvalStatus === 'REJECTED' ? (
                    <div>
                      <LdsTooltip hideIcon>
                        <LdsTooltip.Text>{entry.approvalStatus}</LdsTooltip.Text>
                        {entry.remarks ? (
                          <LdsTooltip.Description>{entry.remarks}</LdsTooltip.Description>
                        ) : (
                          <LdsTooltip.Description>No remarks found!</LdsTooltip.Description>
                        )}
                      </LdsTooltip>
                    </div>
                  ) : (
                    <div>{entry.approvalStatus}</div>
                  )}
                  </td>
                  <td className='table-ends'>
                    <div className="icon-col">
                      <LdsIcon className="table-icons" name='InfoFill' onClick={() => { setAuditIsModalOpen(true); setCurTacticId(entry.omms_overview_detail_id) }} />

                    </div>
                  </td>

                </tr>)}
            </tbody> : (<tbody>
              <div>
                <span className='no-data'>No Data Available</span>
              </div>
            </tbody>
            )
          ) : <LdsLoadingSpinner size={50} animationSpeed={500} style={{ zIndex: '3', position: 'absolute', marginLeft: '45%', marginTop: '3rem', height: '20rem' }} />}
        </LdsTable>
        <div >
          {checkArchiveRole() && <div className="restoreButton" >
            <LdsButton onClick={() => modalOpen()}>Archive</LdsButton>
            <LdsModal
              modalId="testModal"
              open={isModalOpen}
              setModalOpen={setIsModalOpen}
              heading="Are you sure you want to archive this record?"
            >

              <div className="space">
                <LdsButton onClick={() => { toArchivePage(); handleClick() }}>Yes</LdsButton>
                <LdsButton onClick={() => { handleClick() }}>No</LdsButton>
              </div>

            </LdsModal>

          </div>}
        </div>
      </LdsModal>
      <LdsTable style={{ minHeight: '17rem' }} >
        <thead>
          <tr>


            <th scope="col" className="table-ends">
              <div class="attriDivCore">
                Delivery Source
                <div class="iconStyle" style={{ display: 'flex', alignItems: 'initial', maxHeight: '1rem', marginTop: '0.3rem' }}>
                  <LdsIcon
                    description="MagnifyingGlass"
                    label="search"
                    name="MagnifyingGlass"
                    class="icon-size"
                    style={{
                      height: '15px',
                      marginBottom: '-2rem',
                      maxWidth: '2.5rem',
                      minWidth: '2.5rem'
                    }}
                    onClick={() => { setIsON1(!statusSearchFld1) }}
                  />
                </div>
              </div>
              {statusSearchFld1 &&
                <div style={{ width: '12rem' }}>
                  <LdsTextField
                    className='text-fld'
                    id='orgName'
                    value={deliverySource}
                    onChange={(e) => setDeliverySource(e.target.value.replace(/\s*,\s*/g, ',').replace(/\s+/g, ' '))}
                    onKeyDown={(e) => { if (e.key === "Enter") { searchFld() } }}
                    name='orgName' />
                </div>
              }
            </th>
            <th scope="col" className='table-ends'>
              <div class="attriDivCore">
                Supplier Name
                <div class="iconStyle" style={{ display: 'flex', alignItems: 'initial', maxHeight: '1rem', marginTop: '0.3rem' }}>
                  <LdsIcon
                    description="MagnifyingGlass"
                    label="search"
                    name="MagnifyingGlass"
                    class="icon-size"
                    style={{
                      height: '15px',
                      marginBottom: '-2rem',
                      maxWidth: '2.5rem',
                      minWidth: '2.5rem'
                    }}
                    onClick={() => {
                      setIsON2(!statusSearchFld2);
                    }}

                  />
                </div>
              </div>
              {statusSearchFld2 &&
                <div style={{ width: '12rem' }}>
                  <LdsTextField
                    className='text-fld'
                    id='orgName'
                    value={supplierName}
                    onChange={(e) => setSupplierName(e.target.value.replace(/\s*,\s*/g, ',').replace(/\s+/g, ' '))}
                    onKeyDown={(e) => { if (e.key === "Enter") { searchFld() } }}
                    name='orgName' />
                </div>
              }
            </th>
            <th scope="col" className='table-ends'>
              <div class="attriDivCore">
                Platform
                <div class="iconStyle" style={{ display: 'flex', alignItems: 'initial', maxHeight: '1rem', marginTop: '0.3rem' }}>
                  <LdsIcon
                    description="MagnifyingGlass"
                    label="search"
                    name="MagnifyingGlass"
                    class="icon-size"
                    style={{
                      height: '15px',
                      marginBottom: '-2rem',
                      maxWidth: '2.5rem',
                      minWidth: '2.5rem'
                    }}
                    onClick={() => { setIsON3(!statusSearchFld3) }}
                  />
                </div>
              </div>
              {statusSearchFld3 &&
                <div style={{ width: '12rem' }}>
                  <LdsTextField
                    className='text-fld'
                    id='orgName'
                    value={platform}
                    onChange={(e) => setPlatform(e.target.value.replace(/\s*,\s*/g, ',').replace(/\s+/g, ' '))}
                    onKeyDown={(e) => { if (e.key === "Enter") { searchFld() } }}
                    name='orgName' />
                </div>
              }
            </th>
            <th scope="col" className='table-ends'>
              <div class="attriDivCore">
                Contact Type
                <div class="iconStyle" style={{ display: 'flex', alignItems: 'initial', maxHeight: '1rem', marginTop: '0.3rem' }}>
                  <LdsIcon
                    description="MagnifyingGlass"
                    label="search"
                    name="MagnifyingGlass"
                    class="icon-size"
                    style={{
                      height: '15px',
                      marginBottom: '-2rem',
                      maxWidth: '2.5rem',
                      minWidth: '2.5rem'
                    }}
                    onClick={() => { setIsON4(!statusSearchFld4) }}
                  />
                </div>
              </div>
              {statusSearchFld4 &&
                <div style={{ width: '12rem' }}>
                  <LdsTextField
                    className='text-fld'
                    id='orgName'
                    value={contactType}
                    onChange={(e) => setContactType(e.target.value.replace(/\s*,\s*/g, ',').replace(/\s+/g, ' '))}
                    onKeyDown={(e) => { if (e.key === "Enter") { searchFld() } }}
                    name='orgName' />
                </div>
              }
            </th>
            <th scope="col" className='table-ends'>
              <div class="attriDivCore">
                Delivery Channel
                <div class="iconStyle" style={{ display: 'flex', alignItems: 'initial', maxHeight: '1rem', marginTop: '0.3rem' }}>
                  <LdsIcon
                    description="MagnifyingGlass"
                    label="search"
                    name="MagnifyingGlass"
                    class="icon-size"
                    style={{
                      height: '15px',
                      marginBottom: '-2rem',
                      maxWidth: '2.5rem',
                      minWidth: '2.5rem'
                    }}
                    onClick={() => {
                      setIsON9(!statusSearchFld9);
                    }}
                  />
                </div>
              </div>
              {statusSearchFld9 &&
                <div style={{ width: '12rem' }}>
                  <LdsTextField
                    className='text-fld'
                    id='orgName'
                    value={deliveryChannel}
                    onChange={(e) => setDeliveryChannel(e.target.value.replace(/\s*,\s*/g, ',').replace(/\s+/g, ' '))}
                    onKeyDown={(e) => { if (e.key === "Enter") { searchFld() } }}
                    name='orgName' />
                </div>
              }
            </th>
            <th scope="col" className='table-ends'>
              <div class="attriDivCore">
                Delivery Source Type
                <div class="iconStyle" style={{ display: 'flex', alignItems: 'initial', maxHeight: '1rem', marginTop: '0.3rem' }}>
                  <LdsIcon
                    description="MagnifyingGlass"
                    label="search"
                    name="MagnifyingGlass"
                    class="icon-size"
                    style={{
                      height: '15px',
                      marginBottom: '-2rem',
                      maxWidth: '2.5rem',
                      minWidth: '2.5rem'
                    }}
                    onClick={() => {
                      setIsON8(!statusSearchFld8);
                    }}
                  />
                </div>
              </div>
              {statusSearchFld8 &&
                <div style={{ width: '12rem' }}>
                  <LdsTextField
                    className='text-fld'
                    id='orgName'
                    value={deliverySourceType}
                    onChange={(e) => setDeliverySourceType(e.target.value.replace(/\s*,\s*/g, ',').replace(/\s+/g, ' '))}
                    onKeyDown={(e) => { if (e.key === "Enter") { searchFld() } }}
                    name='orgName' />
                </div>
              }
            </th>


            <th className="table-ends" scope="col">Cap</th>
            {/* <th className="table-ends see-more" scope="col" onClick={()=>allCheck()}>See More</th> */}
          </tr>
        </thead>
        {!loader ? (data && data.length > 0 ? (
          <tbody style={{ zIndex: '1' }}>
            {isData.map((entry, rowIndex) =>
              <tr role="row" key={rowIndex} className={`table-ends ${entry.approvalStatus === 'REJECTED' ? 'rejected' : ''}`}>
                <td className='table-ends'><div className='table-click' onClick={() => {
                  setSee(true);
                  commonvalueCheck(
                    {
                      dSource: entry.deliverySource,
                      sName: entry.supplierName,
                      dSType: entry.deliverySourceType,
                      dChannel: entry.deliveryChannel,
                      platform: entry.platform,
                      cType: entry.contactType,
                      aStatus: entry.approvalStatus,
                      iscap: entry.isCap === true ? 'yes' : 'no',
                      isRm: entry.isRm === true ? 'yes' : 'no',
                      isPld: entry.isPld === true ? 'yes' : 'no',
                      isDigital: entry.isDigital === true ? 'yes' : 'no',
                    }
                  )
                }} ><div><LdsIcon className='p-icon' name='PlusCircle' /></div>{entry.deliverySource}</div></td>
                <td className='table-ends'>{entry.supplierName}</td>
                <td className='table-ends'>{entry.platform}</td>
                <td className='table-ends'>{entry.contactType}</td>
                <td className='table-ends'>{entry.deliveryChannel}</td>
                <td className='table-ends'>{entry.deliverySourceType}</td>
                <td className='table-ends'>{entry.isCap ? "*" : "-"}</td>
              </tr>)}
            {/* <td></td> */}
          </tbody>) : (<tbody>
            <div>
              <span className='no-data'>No Data Available</span>
            </div>
          </tbody>
        )
        ) : <LdsLoadingSpinner size={50} animationSpeed={500} style={{ zIndex: '3', position: 'absolute', marginLeft: '47vW', marginTop: '3rem', height: '20rem' }} />}

      </LdsTable>

      <LdsPagination
        onLinkClick={clickLink}
        onNextClick={clickNext}
        onPrevClick={clickPrev}
        currentPage={currentPage}
        totalPages={totalPages}
        setCurrentPage={setCurrentPage}
        pageRangeVisible={10}
      />
      <HistoryModal
        modalOpen={isAuditModalOpen}
        setAuditIsModalOpen={setAuditIsModalOpen}
        tacticId={curTacticId}
        sysName="omms"
      />

    </>
  )


}
